//Startup contact page cards
.contact-card {
    display: flex;
    align-content: center;
    align-items: center;
    .icon i {
        font-size: 26px;
        color: $primary;
        position: relative;
    }
    .contact-info {
        margin: 0 30px;
        .contact-name {
            color: $placeholder;
        }
        .contact-details {
            .details-text {
                font-size: 16px;
                color: $blue-grey !important;
            }
        }
    }
}

.contact-subheading {
    font-family: "Nexa Light", sans-serif;
    //font-weight: 600;
    margin: 0 auto;
    padding: 10px 20px;
    font-size: 18px;
    color: $title-grey;
}

/* ==========================================================================
7. Submit button
========================================================================== */

#contact-button {
    transition: all 0.2s;

    &.is-success {
        box-shadow: 0 14px 26px -12px rgba(95, 207, 128, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(95, 207, 128, 0.2) !important;
        opacity: 0.95;
        background-color: #5fcf80 !important;
        border-color: #5fcf80 !important;

        font-weight: bold;
    }

    &.is-error {
        box-shadow: 0 14px 26px -12px rgba(235, 58, 75, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(235, 58, 75, 0.2) !important;
        opacity: 0.95;
        background-color: #eb3a4b !important;
        border-color: #eb3a4b !important;

        font-weight: bold;
    }

    &.is-loading {
        box-shadow: 0 14px 26px -12px $primary-shadow-from, 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px $primary-shadow-to !important;
        opacity: 0.95;

        &::after {
            border-radius: 100px !important;
            border: 2px solid #FAFAFA !important;
        }
    }
}