/*! _agency.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Agency Kit SCSS file
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Wallop fullscreen slider
1. Service and icon boxes
2. Section title
3. Homepage portfolio
4. Team section
5. Parallax counter
6. CLient logos carousel
7. Testimonials carousel
8. Latest posts
9. Services cards
10. Process blocks section
11. Google Map
12. Contact form
13. Portfolio page
14. Blog main page
15. Blog post
16. Media queries
=============================================================================
***/

/* ==========================================================================
0. Wallop fullscreen slider
========================================================================== */
.Wallop {
    top: -65px;
    .Wallop-list {
        width: 100%;
        height: 100%;
    }
    .Wallop-item {
        width: 100%;
        height: 100%;
        min-height: 100vh;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .Wallop-buttonPrevious, .Wallop-buttonNext {
        position: absolute;
        top: calc(50% - 27px);
        background: none;
        border: none;
        cursor: pointer;
        z-index: 6;
        &:hover {
            i {
                opacity: 0.8;
                transform: scale(1.05);
            }
        }
        i {
            font-size: 3.5rem;
            color: $white;
            transition: all .4s;
        }
        &:active, &:focus {
            outline: none !important,
        }
    }
    .Wallop-buttonPrevious {
        left: 20px;
    }
    .Wallop-buttonNext {
        right: 20px;
    }
    .Wallop-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,0.3);
        z-index: 1;
    }

    //Pagination
    .Wallop-pagination {
        text-align: center;
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        bottom: 40px;
        z-index: 5;
    }

    .Wallop-dot {
        text-indent: -9999px;
        border: 0;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        padding: 0;
        margin: 5px;
        background-color: #ccc;
        cursor: pointer;
        -webkit-appearance: none;  
        &:active, &:focus {
            outline:  none !important;
        }
    }

    .Wallop-dot--current {
        background-color: $primary;
    }
    .Wallop-caption-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        position: relative;
    }
    .Wallop-caption {
        font-family: 'Nexa Bold', sans-serif;
        &.is-centered {
            font-size: 3rem;
            height: 100%;
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            color: $white;
            z-index: 5;
            text-align: center;
            .main-inner {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            h1 {
                font-weight: bolder !important;
                text-transform: uppercase;
            }
            h4 {
                font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
                font-size: 40%;
                padding: 0;
                font-weight: 300;
            }
        }
    }
    .caption-action {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        a {
            font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

        }
    }
}

.hero-foot.is-pulled {
    margin-top: -65px;
}

//Wallop slider media queries (mobile)
@media (max-width: 768px) {
    .Wallop-caption {
        font-size: 2.8rem !important;
        h1, h4 {
            padding: 0 10% !important;
        }
    }
}


/* ==========================================================================
1. Service and icon boxes
========================================================================== */

.service-box {
    .service-title {
        font-family: 'Nexa Bold', sans-serif;
        font-size: 2.4rem;
        font-weight: 600;
        color: $blue-grey;
    }
    p {
        padding: 5px 0;
    }
    .action {
        padding: 10px 0;
        a {
            border-width: 2px;
            font-weight: 600;
        }
    }
}

.agency-icon-box {
    i {
        font-size: 3.4rem;
        font-weight: normal;
        color: $primary;
    }
    .box-title {
        padding: 10px 0;
        font-size: 16px;
        line-height: 22px;
        font-family: 'Nexa Light', sans-serif;
        font-weight: 600;
        color: $blue-grey;
    }
    .box-content {
        color: $title-grey;
    }
}

@media (max-width: 767px) {
    .agency-icon-box, .service-box {
        text-align: center;
    }
    .service-box {
        padding-bottom: 20px;
        .title-divider {
            margin: 0 auto;
        }
    }
}

/* ==========================================================================
2. Section title
========================================================================== */

.subtitle:not(:last-child), .title:not(:last-child) {
    margin-bottom: 0 !important;
}

.is-header-caption {
    padding: 100px 0;
}

.centered-title {
    text-align: center;
    h2 {
        font-family: 'Nexa Bold', sans-serif;
        font-size: 2.4rem;
        font-weight: 600;
        color: $blue-grey;
    }
    .subheading {
        font-family: "Nexa Light", sans-serif;
        font-weight: 600;
        max-width: 70%;
        margin: 0 auto;
        padding: 10px 20px;
        font-size: 18px;
        color: $title-grey;
    }
    .title-divider {
        margin: 10px auto;
    }
    &.is-inverted {
        h2, .subheading {
            color: $white;
        }
    }
}

//Sub page title
.subpage-title {
    font-family: 'Nexa Bold', sans-serif;
    font-weight: 600 !important;
}

/* ==========================================================================
3. Homepage portfolio
========================================================================== */
.portfolio {
    .portfolio-item {
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        max-height: 800px;
        height: auto;
        margin: 20px 10px;
        &:hover {
            .circle-overlay {
                transform: scale(35);
            }
            .project-title {
                opacity: 1;
                transform: translateY(0);
                transition-delay: 0 !important;
            }
            .project-link {
                color: $white;
                opacity: 1;
                transform: translateY(0);
                transition-delay: 0 !important;
            }
        }
        img {
            border-radius: 10px;
            max-height: 800px;
            display: block;
        }
        .circle-overlay {
            position: absolute;
            top: -60px;
            left: -60px;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background: $primary;
            transform: scale(1);
            transition: all 0.5s;
            z-index: 1;
        }
        .project-info {
            position: absolute;
            margin: 0 auto;
            top: 40%;
            left: 0;
            right: 0;
            z-index: 2;
            color: $white;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .project-title {
            font-family: 'Nexa Bold', sans-serif;
            font-size: 1.6rem;
            font-weight: 600;
            padding-bottom: 0;
            opacity: 0;
            transform: translateY(20px);
            transition: all 0.3s;
            transition-delay: 0.1s;
        }
        .project-link {
            opacity: 0;
            transform: translateY(20px);
            transition: all 0.3s;
            transition-delay: 0.2s;
            a {
                color: $white;
                font-family: 'Nexa Light', sans-serif;
                font-size: 1.2rem;
                i {
                    position: relative;
                    top: 8px;
                    opacity: 0;
                    transform: translateX(0);
                    transition: all 0.3s ease-out;
                }
                &:hover i {
                    opacity: 1;
                    transform: translateX(10px);
                }
            }
        }
    }

}

/* ==========================================================================
4. Team section
========================================================================== */
.modern-team {
    .modern-team-item {
        padding: 0 5px;
        width: 33%;
        .item-img {
            &:before {
                content: "";
                background-color: rgba(74, 184, 166, .9);
            }
        }
        img {
            border-radius: 10px;
        }
        &:hover .item-img {
            border-radius: 10px;
        }
    }
}
//Team section media query (mobile)
@media (max-width: 767px) {
    .modern-team-item {
        width: auto !important;
    }
}

/* ==========================================================================
5. Parallax counter
========================================================================== */
.parallax-counter.is-primary i {
    color: $primary !important;
}

/* ==========================================================================
6. Client logos carousel
========================================================================== */
.client-logo {
    max-height: 80px;
}

.clients-logo-carousel {
    text-align: center;
    max-width: 950px;
    margin: 0 auto;
    &:hover .slick-custom {
        opacity: 1;
    }
    .carousel-item {
        margin: 0 20px;
    }
    .image-wrapper {
        position: relative;
        .stack-logo {
            max-height: 130px;
            margin: 0 auto;
        }
    }
    .slick-custom {
        opacity: 0;
        top: 23px;
    }
    .slick-dots {
        bottom: -60px !important;
    }
    .slick-prev:before, .slick-next:before {
        color: $muted-grey;
    }
    .slick-custom.is-next {
        right: -15px;
    }
    .slick-custom.is-prev {
        left: -15px;
    }
}
//Clients logos carousel media query (mobile)
@media (max-width: 768px) {
    .clients-logo-carousel {
        .slick-dots {
            display: none !important;
        }
    }
}


/* ==========================================================================
7. Testimonials carousel
========================================================================== */
.people-carousel {
    .people-carousel-item {
        .item-wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            align-content: center; 
        }
        .image-column {
            width: 60%;
            display: block;
            position: relative;
        }
        .content-column {
            width: 40%;
            display: block;
            position: relative;
        }
        .carousel-img {
            text-align: center;
            img {
                display: block;
                margin: 0 auto;
            }
        }
    }
    .testimonial-title {
        font-size: 1.7rem;
        color: $blue-grey;
        font-family: 'Nexa Bold', sans-serif;
        font-weight: 600;
        position: relative;
        i {
            position: absolute;
            top: -25px;
            right: 0;
            color: $primary;
        }
    }
    .testimonial-content {
        p {
            padding-bottom: 10px;
        }
    }
    .customer-info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .customer-avatar img {
            height: 45px;
            width: 45px;
            border-radius: 50%;
            border: 1px solid $fade-grey;
        }
        .customer-block {
            margin: 0 10px;
            position: relative;
            .name {
                font-family: 'Nexa Light', sans-serif;
                font-weight: 600;
                color: $blue-grey;
            }
            .position {
                font-size: 90%;
                color: $title-grey;
            }
        }
        .company-logo {
            max-width: 100px !important;
            margin-left: auto;
        }
    }
    .mobile-spacer {
        padding-bottom: 80px;
    }

    &.slick-dotted.slick-slider {
        margin-bottom: 0 !important;
    }
    .slick-dots {
        bottom: 40px;
        right: 18%;
        width: auto;
    }
}

//Testimonials carousel media query (mobile)
@media (max-width: 767px) {
    .people-carousel {
        .content-column {
            width: 100% !important;
        }
        .testimonial-title {
            font-size: 1.4rem; 
        }
        .slick-dots {
            left: 0 !important;
            right: 0 !important;
        }
    }
}


/* ==========================================================================
8. Latest posts
========================================================================== */
.latest-posts {
    .card {
        border-radius: 5px;
        box-shadow: 0 3px 10px 4px rgba(0,0,0,.04);
        border: 1px solid $fade-grey;
        .card-image figure img {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
        figure.author {
            position: absolute;
            bottom: -24px;
            right: 20px;
            .img-circle {
                border: 2px solid $white;
                border-radius: 50%;
            }
        }
        .media {
            margin: 0;
        }
        .title {
            font-family: 'Nexa Bold', sans-serif;
            font-weight: 600;
        }
        .timestamp {
            font-size: 85%;
            color: $title-grey;
            padding: 5px 0;
            i {
                margin-right: 7px;
            }
        }
        .post-exerpt {
            padding: 10px 0 20px 0;
        }
    }
}

/* ==========================================================================
9. Services cards
========================================================================== */
.services-cards {
    .feature-card {
        height: 310px;
        padding: 50px 0;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .card-feature-description {
        font-size: 95%;
    }
    .card-icon {
        //padding-top: 50px;
        i {
            font-size: 5rem;
            color: $primary;
        }
    }
    &.is-minimal {
        padding: 0 8%;
        i {
            color: $blue-grey;
            font-size: 4.5rem;
        }
        .feature-card:hover {
            i {
                color: $primary;
            }
        }
    }
}

//Services cards media query (mobile)
@media (max-width: 768px) {
    .services-cards.is-minimal {
        padding: 0 !important;
    }
}

/* ==========================================================================
10. Process blocks section
========================================================================== */
.process-block {
    position: relative;
    text-align: center;
    .process-icon {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 5rem;
        .icon-wrapper {
            position: relative;
            width: 180px;
            height: 180px;
            border-radius: 50%;
            background: $white;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &.has-line:after {
        content: '';
        height: 4px;
        width: 80px;
        background: $fade-grey;
        position: absolute;
        top: 88px;
        right: -14%;
        border-radius: 50px;
    }
    .process-number {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        font-size: 14px;
        font-weight: bolder;
        position: absolute;
        top: 13px;
        right: 0;
        background: $primary;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .process-info {
        display: flex;
        justify-content: center;
        align-items: center;
        .step-number {
            font-size: 8rem;
            font-weight: bolder;
            color: $fade-grey;
            margin-right: 15px;
        }
        .details {
            text-align: left;
        }
        .motto {
            font-size: 2.4rem;
            font-weight: 700;
            color: $blue-grey;
        }
        .description {
            font-size: 95%;
            color: $muted-grey;
        }
    }
}

//Process blocks section media query (mobile)
@media (max-width: 768px) {
    .process-block.has-line:after {
        display: none;
    }
}

//CTA
.cta-text {
    font-family: 'Nexa Bold', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
}


/* ==========================================================================
11. Google Map
========================================================================== */
.half-map {
    min-height: 550px;
}

/* ==========================================================================
12. Contact form
========================================================================== */
.contact-form-wrapper {
    min-height: 500px;
    padding: 50px 0;
    background: $fade-grey;
    .container {
        padding: 50px 0;
    }
    .columns {
        padding: 30px 0;
    }
}

.form-title {
    font-family: 'Nexa Bold', sans-serif;
    font-size: 1.8rem;
    font-weight: 700;
    color: $blue-grey;
}

.shadow-title {
    font-family: 'Nexa Light', sans-serif;
}

.shadow-description {
    font-family: 'Nexa Bold', sans-serif;
}

/* ==========================================================================
13. Portfolio page
========================================================================== */
.portfolio-item {
    .item-title {
        font-family: 'Nexa Bold', sans-serif;
        font-size: 2.3rem;
    }
    .project-description {
        p {
            padding-bottom: 10px;
        }
    }
    .made-for {
        font-family: 'Nexa Bold', sans-serif;
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
            max-height: 80px;
        }
    }
    label {
        font-family: 'Nexa Light', sans-serif;
        text-transform: uppercase;
        font-weight: 200;
        font-size: 90%;
        color: $muted-grey;
    }
    .project-category {
        display: flex;
        justify-content: space-between;
        align-items: center;
        //padding-bottom: 10px;
        span {
            font-family: 'Nexa Bold', sans-serif;
        }
        i {
            font-size: 1.8rem;
            color: $primary;
        }
    }
    .inline-list {
        padding: 10px 0;
    }
    .project-date {
        padding: 10px 0;
        font-family: 'Nexa Bold', sans-serif;
    }
    .fa-social {
        font-size: 1.4rem;
        color: $title-grey;
        cursor: pointer;
        transition: color 0.3s;
        &:hover {
            color: $primary;
        }
    }
    .navigation {
        padding: 20px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        a {
            width: 146px;
            margin: 0 5px;
            i {
                font-size: 18px;
                &.is-right {
                    position: relative;
                    right: -14px;
                }
            }
        }
    }
}


/* ==========================================================================
14. Blog main page
========================================================================== */

.blog-hero {
    padding: 8rem 1.5rem;
}

.flex-card.is-post {
    border-radius: 6px;
    &.is-pulled-top {
        margin-top: -180px;
    }
    .header {
        min-height: 170px;
        width: 100%;
        background: $primary;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        h2.post-title {
            font-family: 'Nexa Bold', sans-serif;
            position: relative;
            color: $white;
            font-size: 1.6rem;
            font-weight: 500;
            padding: 0 20px;
            z-index: 1;
        }
        h4.post-subtitle {
            position: relative;
            color: $white;
            font-family: 'Nexa Light', sans-serif;
            font-size: 1.1rem;
            font-weight: 400;
            padding: 10px 20px;
            z-index: 1;
        }
        .author-avatar {
            position: absolute;
            right: 60px;
            bottom: -50px;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            border: 5px solid $white;
            z-index: 1;
            display: block;
            img {
                border-radius: 50%;
                position: relative;
                left: -0.5px;
                transform: scale(1.02);
            }
        }
        .header-overlay {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: $coal;
            opacity: 0.5;
            z-index: 0;
        }
    }
    .post-body {
        padding: 20px;
        div span {
            font-size: 90%;
            color: $muted-grey;
        }
        div a.author-name {
            font-family: 'Nexa Light', sans-serif;
            color: $blue-grey;
            &:hover {
                color: $primary;
            }
        }
        p {
            padding: 10px 0;
            color: $blue-grey;
        }
        hr {
            background-color: $fade-grey;
        }
        a {
            color: $primary;
            &.button {
                color: $muted-grey;
                font-weight: 500;
                font-family: 'Nexa Bold', sans-serif;
                &:hover {
                    color: $blue-grey !important;
                }
            }
            &:hover {
                opacity: 0.7;
            }
        }
        small {
            color: $muted-grey;
            font-size: 0.9rem;
        }
        .footer-details {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .likes-count, .comments-count {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                i {
                    font-size: 1.5rem;
                    font-weight: 900;
                    //position: relative;
                    //top: 4px;
                    color: $title-grey;
                }
                .stat {
                    font-size: 90%;
                    color: $title-grey;
                    font-weight: 500;
                    padding: 0 4px
                }
            }
            .likes-count {
                margin-right: 10px;
            }
            .comments-count {
                margin: 0 10px;
            }
        }
    }
}

//Like button in blog pages
.fab-btn {
    box-sizing: border-box;
    width: 56px;
    height: 56px;
    padding: 16px;
    border: none;
    border-radius: 50%;
    color: $white;
    transition: all 0.2s ease;
    cursor: pointer;
    z-index: 1;
    &:active, &:focus {
        outline: none !important;
    }
    &.mini {
        width: 48px;
        height: 48px;
        padding: 12px;
        transform: rotate(-600deg);
        background: $primary;
    }
    &.like { 
        background: $title-grey !important;
        transform: rotate(0deg);
        position: absolute;
        right: 28%;
        bottom: -22px;
        box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
        i.unliked {
            display: block;
            color: $white;
            position: relative;
            z-index: 1;
        }
        i.liked {
            display: none;
            color: $white;
            position: relative;
            z-index: 1;
        }
        &.is-active {
            .like-overlay {
                transform: scale(1);
            }
            i.unliked {
                display: none;
            }
            i.liked {
                display: block;
            }
        }
        &.is-single {
            bottom: -34px;
        }
    }
    .like-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        .like-overlay {
            position: absolute;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            top: -36px;
            left: -24px;
            background: $red;
            transform: scale(0);
            transition: transform 0.4s;
            z-index: 0;
        }
    }
}


//Blog page media query (mobile)
@media (max-width: 768px) {
    .blog-section {
        padding: 5px !important;
    }
    .flex-card.is-post {
        &.is-pulled-top {
            margin-top: -80px;
        }
        .header .author-avatar {
            right: 20px !important;
            bottom: -30px !important;
            width: 60px !important;
            height: 60px !important;
            border: 3px solid $white !important;
            img {
                left: 0.5px;
                transform: scale(1);
            }
        }
    }
}

/* ==========================================================================
15. Blog post
========================================================================== */

//Blog sidebar search input
.control.is-blog-search {
    position: relative;
    margin-bottom: 20px;
    input {
        height: 60px;
        width: 100%;
        border: none;
        padding-left: 45px;
        border: 1px solid $fade-grey;
        transition: all 0.5s;
        &:focus {
            box-shadow: inset rgba(143,160,241,0.2) 0 0 0 1px, rgba(213,220,247,0.59) 0 10px 20px;
            border: 1px solid $primary;
            border-radius: 5px;
            + i {
                color: $primary;
            }
        }
        &:focus, &:active {
            outline: none;
        }
    }
    i {
        position: absolute;
        left: 15px;
        top: 22px;
        color: $placeholder;
        font-size: 20px;
        transition: color 0.5s;
    }
}

//Blog single post
.flex-card.is-full-post {
    border-radius: 6px;
    padding: 60px;
    &.has-sidebar {
        padding: 40px;
    }
    &.is-pulled-top {
        margin-top: -180px;
    }
    .post-meta {
        display: flex;
        justify-content: flex-start;
        padding: 10px 30px;
        margin: 0;
        border-bottom: 1px solid $fade-grey;
        img {
            width: 55px;
            height: 55px;
            border-radius: 50%;
        }
        .title-block {
            position: relative;
            padding: 0 20px;
            h2, h4 {
                margin-bottom: 0;
            }
            h2 {
                font-family: 'Nexa Bold', sans-serif;
                font-weight: 700; 
            }
            h4 {
                font-family: 'Nexa Light', sans-serif;
                color: $muted-grey;
                padding: 10px 0;
            }
            .like {
                right: 20px;
                &.is-full {
                    bottom: -32px;
                }
            }
        }
    }
    .post-body {
        padding: 10px 30px;
        .author-name {
            padding: 10px 0 5px 0;
            font-size: 90%;
            color: $title-grey;
            b a {
                color: $blue-grey;
                &:hover {
                    color: $primary;
                }
            }
        }
        p {
            font-size: 16px;
        }
        .timestamp {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 90%;
            color: $title-grey;
            padding: 5px 0 10px 0;
            i {
                font-size: 16px;
                color: $primary;
                margin-right: 5px;
            }
        }
        h5 {
            font-family: 'Nexa Bold', sans-serif;
            font-size: 1.3rem;
            font-weight: 500;
            color: $blue-grey;
            padding: 10px 0;
            margin-bottom: 0;
        }
        .post-image {
            margin: 10px 0;
            border-radius: 10px;

        }
        hr {
            background-color: $fade-grey;
        }
        .share-post {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .share-text {
                font-weight: bold;
                color: $blue-grey;
            }
            .sharing-options {
                i {
                    font-size: 1.4rem;
                    margin: 0 5px;
                    cursor: pointer;
                    color: $title-grey;
                    &:hover {
                        &:first-child  {
                            color: $primary;
                        }
                        &:nth-child(2) {
                            color: $facebook;
                        }
                        &:nth-child(3) {
                            color: $twitter;
                        }
                        &:nth-child(4) {
                            color: $linkedin;
                        }
                        &:nth-child(5) {
                            color: $google-plus;
                        }
                        &:nth-child(6) {
                            color: $reddit;
                        }
                        &:nth-child(7) {
                            color: $tumblr;
                        }
                    }
                }
            }
        }
    }
}

//Comment count section
.comment-count {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    border-radius: 6px;
    i {
        font-size: 2rem;
        font-weight: 500;
        color: $title-grey;
    }
    .text {
        font-size: 1.1rem;
        font-weight: 400;
        color: $title-grey;
        margin-left: 20px;
        span.count-number {
            font-size: 1.1rem;
        }
    }
    button {
        margin-left: auto;
        color: $muted-grey !important;
    }
}

//Write comment card
.compose-card {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: unset;
    .control-wrapper {
        width: 100%;
        padding-left: 20px;
        .textarea-button {
            background: $fade-grey;
        }
    }
}

//Comments list
.comments-list {
    border-radius: 6px;
    padding: 40px;
    .media {
        margin: 0 !important;
        padding: 10px 0 !important;
        border: none !important;
        .media-left img {
            border-radius: 50%;
        }
        small {
            color: $title-grey;
            .reply a {
                font-size: 95%;
                padding: 0 20px;
            }
        }
        small a {
            color: $title-grey;
            font-size: 16px;
            i {
                vertical-align: middle;
                font-size: 16px;
                margin: 0 5px;
                &:hover {
                    color: $red !important;
                }
            }
            &:hover {
                color: $primary;
                i {
                    color: $red;
                }
            }
        }
        .count {
            color: $title-grey;
            font-weight: 500;
        }
        .timestamp {
            display: block;
            font-size: 90%;
            color: $title-grey;
        }
        strong {
            font-family: 'Nexa Bold';
            font-weight: 600 !important;
        }
    }
}

.content p:not(:last-child) {
    margin-bottom: 0 !important;
}

//Blog sidebar widgets
.flex-card {
    .card-header {
        font-family: 'Nexa Bold', sans-serif;
        padding: 20px;
        font-size: 1.2rem;
        font-weight: 500;
        color: $blue-grey;
    }
    .card-panel {
        //Recent posts
        .recent-posts {
            .recent-post {
                padding: 10px 20px;
                border-bottom: 1px solid $fade-grey;
                .post-title {

                }
                .post-title a {
                    color: $blue-grey;
                    font-weight: 600;
                    &:hover {
                        color: $primary;
                    }
                }
                .post-meta {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    img {
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                    }
                }
                span {
                    display: block;
                    font-size: 90%;
                    color: $title-grey;
                }
            }
        }
        //Latest comments
        .latest-comments {
            .latest-comment {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 16px 20px;
                border-bottom: 1px solid $fade-grey;
                img {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
                .comment-info {
                    .name a {
                        color: $blue-grey;
                        font-weight: 600;
                        &:hover {
                            color: $primary;
                        }
                    }
                    .post-ref a {
                        font-size: 90%;
                        color: $title-grey;
                        &:hover {
                            color: $primary;
                        }
                    }
                }
            }
        }
        //Archives and categories
        .archives, .post-categories {
            .archived-month, .post-category {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 16px 20px;
                border-bottom: 1px solid $fade-grey;
                span a {
                    color: $blue-grey;
                    font-weight: 600;
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
        //Tags
        .tag {
            margin: 5px;
        }
    }
}

//Flex title
.title.flex-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span.material-icons {
        font-size: 2.4rem;
        padding: 8px;
        border: 2px solid $white;
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
            border: 2px solid $primary;
            background: $primary;
            color: $white;
        }
    }
}

//Single post media query (mobile)
@media (max-width: 768px) {
    .flex-card.is-full-post {
        &.is-pulled-top {
            margin-top: -80px;
        }
        .post-body {
            padding: 20px !important;
        }
    }
    .title.flex-title {
        justify-content: center;
    }
}

/* ==========================================================================
16. CTA
========================================================================== */

.minimal-feature {

    .cta-title {
        font-family: 'Nexa Bold', sans-serif;
        font-size: 2.4rem;
        font-weight: 600;
        color: $white;
    }

    .cta-subtitle {
        font-family: "Nexa Light", sans-serif;
        font-weight: 600;
        margin: 0 auto;
        padding: 10px 0 0;
        font-size: 16px;
        color: $white;
    }

    .cta-title-divider {
        margin: -5px 0 10px;
        width: 80px;
        height: 5px;
        background:  $white;
    }

    //Hover raised buttons

    .button {

        &.cta-raised:hover {
            box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2) !important;
            //opacity: 0.8;
        }
    }
}

/* ==========================================================================
17. Media queries
========================================================================== */

//Mobile
@media (max-width: 767px) {
    .title.main-title {
        font-size: 3rem !important;
    }
    .flex-card {
        &.is-post {
            h2 {
                font-size: 1.3rem !important;
            }
        }
        &.is-full-post {
            padding: 10px !important;
            .post-body {
                padding: 20px 10px !important;
            }
        }
    }
    .post-meta {
        margin: 0 !important;
        padding: 10px !important;
        .title-block {
            padding: 0 !important;
            h2 {
                font-size: 1.5em !important;
            }
        }
    }
    .post-body {
        padding: 20px 10px !important;
    }
    .compose-card {
        .control-wrapper {
            padding: 0;
        }
    }
    .subheading {
      max-width: 100% !important;
    }
}

//Tablet orientation portrait
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    .portfolio-item {
        img {
            width: 100%;
        }
    }
    .agency-icon-box, .service-box {
        text-align: center;
        .title-divider {
            margin: 0 auto;
        }
    }
    .flex-card.is-post, .flex-card.is-full-post {
        padding: 40px;
        .post-meta {
            padding: 10px 0;
        }
    }
}